<template>
	<!-- 错题主页面 -->
	<div class="container">
		<div class="row clearfix displayFlex" id="clearfix" >
			<Nav_class v-on:queryUserListStudentitem="getUserListStudentitem"></Nav_class>
			<div class="Collect column" style="margin: 0 15px;flex: 1 1 auto;overflow-y: auto;">
				<div class="Collect_con">
					<div class="Collect_con_div">
						<span class="IntelligenceShengspan" @click="cuotiliebiaoshow=1;queryuserplanlist()"
							:class="[cuotiliebiaoshow==1?'':'IntelligenceShengspan2']">{{$store.state.teachlanguagedata.myhomework}}</span>
						<span class="IntelligenceShengspan" @click="cuotiliebiaoshow=2;queryallerrorbooklist('queryAllErrorBookList')"
							:class="[cuotiliebiaoshow==2?'':'IntelligenceShengspan2']">{{$store.state.teachlanguagedata.incorrectsubject}}</span>
						<span class="IntelligenceShengspan" @click="cuotiliebiaoshow=3;queryallerrorbooklist()"
							:class="[cuotiliebiaoshow==3?'':'IntelligenceShengspan2']">{{$store.state.teachlanguagedata.personalizedpractice}}</span>
					</div>
					<div id="homeworklist" v-if="cuotiliebiaoshow==1">
						<el-table :data="UserPlanList"
							:header-cell-style="{background:'#F3F5F9',color:'#606266'}">
							<el-table-column prop="type" :label="$store.state.teachlanguagedata.name">
								<template scope="scope">
									<div class="" style="display: flex;align-items: center;">
										<img v-if="scope.row.planKind=='错题'" src="../../assets/img/cuoti.png" width="48" height="48">
										<img v-else style="width: 40px;height: 40px;margin: 0 0 0 6px;" src="../../assets/img/gexinghua.png">
										<el-popover placement="bottom" width="200" trigger="hover">
											<span>{{scope.row.name}}</span>
											<div slot="reference">
												<span
													style="margin-left: 8px;white-space: nowrap;overflow: hidden;">{{scope.row.name}}</span>
											</div>
										</el-popover>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="beginTime" :label="$store.state.teachlanguagedata.startingtime" :formatter="dateFormat">
								<template v-slot="scope">
									<el-date-picker style="position: absolute;opacity: 0;" v-model="start_time"
										@change="updatebaseInfo(scope.row,start_time,'beginTime')" type="datetime"
										:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
										value-format="timestamp" />
									<div>{{scope.row.beginTime}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="endTime"  :label="$store.state.teachlanguagedata.endtime" :formatter="dateFormat">
								<template v-slot="scope">
									<el-date-picker style="position: absolute;opacity: 0;" v-model="end_time"
										@change="updatebaseInfo(scope.row,end_time,'endTime')" type="datetime"
										:placeholder="$store.state.teachlanguagedata.select" format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
										value-format="timestamp" />
									<div>{{scope.row.endTime}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="joinStatus" :label="$store.state.teachlanguagedata.answerstatus">
								<template slot-scope="scope">
									<div class="">
										<div v-if="scope.row.joinStatus==1">{{$store.state.teachlanguagedata.answered}}</div>
										<div v-else>{{$store.state.teachlanguagedata.unanswered}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column fixed="right" :label="$store.state.teachlanguagedata.operate" width="140">
								<template slot-scope="scope">
									<div class="" style="white-space: nowrap;">
										<span style="margin-right: 10px;" id="report_type" v-if="true">
											<a
												@click="go_to(scope.row.id,scope.row.beginTime,scope.row.endTime,'下载',scope.row)">{{$store.state.teachlanguagedata.download}}</a>
										</span>
										<span style="margin-right: 10px;">
											<!-- 错题表格的正确率 -->
											<a
												@click="go_to(scope.row.id,scope.row.beginTime,scope.row.endTime,'Correct',scope.row)">{{$store.state.teachlanguagedata.correct}}</a>
										</span>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<TopicAssembly class="TopicAssembly" v-else :tableData="tableData"></TopicAssembly>
				</div>
				<div class="IntelligenceSheng" v-if="tableData.length>0&&cuotiliebiaoshow!=1">
					<span class="IntelligenceShengspan" @click="generate()">{{$store.state.teachlanguagedata.preview}}</span>
					<span class="IntelligenceShengspan" v-if="cuotiliebiaoshow==2"
						@click="createErrorbookset()">{{$store.state.teachlanguagedata.postincorrect}}</span>
					<span class="IntelligenceShengspan" v-if="cuotiliebiaoshow==3"
						@click="createErrorbookset('kindName')">{{$store.state.teachlanguagedata.postpersonalizedexercises}}</span>
				</div>
			</div>
		</div>
		<el-dialog :title="$store.state.teachlanguagedata.pleaseenterhomeworkinformation" :visible.sync="worksaveshow">
			<el-form>
				<el-form-item :label="$store.state.teachlanguagedata.homeworkname" :label-width="formLabelWidth">
					<template>
						<el-input style="width: 220px;" v-model="worksavename"></el-input>
					</template>
				</el-form-item>
				<el-form-item :label="$store.state.teachlanguagedata.startingtime" :label-width="formLabelWidth">
					<template>
						<el-date-picker v-model="form.beginTime" value-format="timestamp" :picker-options="starttime"
							type="datetime" :placeholder="$store.state.teachlanguagedata.Selectdateandtime">
						</el-date-picker>
					</template>
				</el-form-item>
				<el-form-item :label="$store.state.teachlanguagedata.endtime" :label-width="formLabelWidth">
					<template>
						<el-date-picker v-model="form.endTime" :picker-options="endTime" value-format="timestamp"
							type="datetime" :placeholder="$store.state.teachlanguagedata.Selectdateandtime">
						</el-date-picker>
					</template>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="worksaveshow = false">{{$store.state.teachlanguagedata.cancel}}</el-button>
				<el-button type="primary" @click="worksave()">{{$store.state.teachlanguagedata.confirm}}</el-button>
			</div>
		</el-dialog>
		<PublishJob ref="PublishJob" :CreateAiQuestionSetShow="CreateAiQuestionSetShow"></PublishJob>
	</div>
</template>

<script>
	import {
		updateBaseInfo
	} from '../../api/base.js'
	import {
		getsetId
	} from '../../api/class.js'
	import {
		queryRelationList,
		queryAiListByCatalogTactics,errorBookQuestionPdf
	} from '../../api/Intelligence.js'
	import {
		queryAllErrorBookList,
		createErrorBookSet,
		save,
		queryUserPlanList,
		queryDetailById,
		queryRelationIdsByPlanId,
		queryErrorListByStudentCatalogTactics
	} from '../../api/Collect.js'
	import Nav_class from '../../components/Nav_class'
	import TopicAssembly from '../../components/TopicAssembly.vue'
	import PublishJob from '../../components/PublishJob/index.vue'
	import BaseTable from '../../components/BasicComponents/BaseTable.vue'
	export default {
		name: 'Class',
		components: {
			Nav_class,
			TopicAssembly,
			PublishJob,
			BaseTable
		},
		data() {
			return {
				tableColumn: [{
					prop: "name",
					label: "名称",
					slot: true,
					slotName: 'name'
				}, {
					prop: "beginTime",
					label: "开始时间",
					slot: true,
					slotName: 'beginTime'
				}, {
					prop: "endTime",
					label: "结束时间",
					slot: true,
					slotName: 'endTime'
				}, {
					prop: "progress",
					label: "提交进度",
					slot: true,
					slotName: 'progress'
				}, {
					prop: "status",
					label: '状态',
					slot: true,
					slotName: 'status'
				}, {
					prop: "status",
					label: "操作",
					slot: true,
					slotName: 'caozuo'
				}],
				pickerOptions: {
					disabledDate(time) {
						return Date.now() - 8.64e7 >= time.getTime()
					}
				},
				end_time: '',
				start_time: '',
				worksavename:'',
				form: {},
				formLabelWidth: '100px',
				worksaveshow: false,
				cuotiliebiaoshow: true,
				value1: '',
				RelationList: [],
				data: {
					ids: ''
				},
				tableData: [],
				UserPlanList: [],
				check_user_type: '',
				check_user_type2: '',
				check_user_type3: '',
				kindName:'',//判断个性化或者错题
				CreateAiQuestionSetShow:2,
			}
		},
		watch: {
			cuotiliebiaoshow(val) {
				if(val==3){
					this.worksavename = "个性化" + Date.parse(new Date())
				}else{
					this.worksavename = "错题" + Date.parse(new Date())
				}
			}
		},
		computed: {
			starttime() {
				let str = "",
					strtime = '';
				let startTimeDate = this.$moment(this.form.beginTime).format("YYYY-MM-DD");
				if (this.form.endTime) {
					let endTimeDate = this.$moment(this.form.endTime).format("YYYY-MM-DD");
					if (startTimeDate == endTimeDate) {
						str = this.$moment(this.form.endTime).format("HH:mm:ss");
					} else {
						str = "23:59:59"
					}
					strtime = "00:00:00 - " + str
				} else {
					strtime = "00:00:00 - 23:59:59"
				}
				return {
					disabledDate: (time) => {
						if (this.form.endTime) {
							return (
								time.getTime() > new Date(this.form.endTime).getTime() - 8.64e6 // 加- 8.64e6则表示包当天
							)
						}
					},
					selectableRange: strtime
				}
			},
			// 结束时间
			endTime() {
				let str = "",
					strtime = '';
				let endTimeDate = this.$moment(this.form.endTime).format("YYYY-MM-DD");
				let nowTimeDate = this.$moment(new Date().getTime()).format("YYYY-MM-DD");
				if (this.form.beginTime) {
					let startTimeDate = this.$moment(this.form.beginTime).format("YYYY-MM-DD");
					if (startTimeDate == endTimeDate) {
						if (endTimeDate == nowTimeDate) {
							str = this.$moment(new Date().getTime()).format("HH:mm:ss");
						} else {
							str = this.$moment(this.form.beginTime).format("HH:mm:ss");
						}
					} else if (nowTimeDate == endTimeDate) {
						str = this.$moment(new Date().getTime()).format("HH:mm:ss");
					} else {
						str = "00:00:00"
					}
				} else {
					if (nowTimeDate == endTimeDate) {
						str = this.$moment(new Date().getTime()).format("HH:mm:ss");
					} else {
						str = "00:00:00"
					}
				}
				strtime = str + " - 23:59:59"
				return {
					disabledDate: (time) => {
						if (this.form.beginTime) {
							return (
								time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.form
									.beginTime)
								.getTime() - 8.64e7 // 加- 8.64e7则表示包当天
							)
						} else {
							return time.getTime() < Date.now() - 8.64e7
						}
					},
					selectableRange: strtime
				}
			},
		},
		mounted() {
			this.queryrelationlist()
		},
		methods: {
			updatebaseInfo(val1, val2, val3) {
				let data = {
					"id": val1.id,
					[val3]: val2
				}
				updateBaseInfo(data).then(res => {
					this.queryuserplanlist()
				})
			},
			dateFormat(row, column) {
				var date = row[column.property];
				if (date == undefined) {
					return "";
				}
				return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
			},
			//下载题集
			get_questionlistpdf(id) {
				let data = {
					setId: id
				}
				let formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				queryDetailById(formData).then(response => {
					if (response.data.result.codeUrl && response.data.result.codeUrl != '') {
						let aLink = document.createElement('a');
						aLink.href = response.data.result.codeUrl;
						aLink.style.display = 'none';
						document.body.appendChild(aLink);
						aLink.click();
						document.body.removeChild(aLink)
					} else {
						this.$message.error('铺码失败,可能有特殊字符');
					}
				})
			},
			go_to(plan_id, start_time, end_time, url, data) {
				queryRelationIdsByPlanId({
					planId: plan_id,
					"relationKind": "questionSet"
				}).then(response => {
					console.log(response);
					if (url == '下载') {
						this.get_questionlistpdf(response.data.result[0])
					} else {
						localStorage.setItem("teachplanIdName", JSON.stringify(data.name))
						localStorage.setItem("teachstart_time", JSON.stringify(start_time))
						localStorage.setItem("teachend_time", JSON.stringify(end_time))
						localStorage.setItem("teachplanIdPlankind", JSON.stringify(data.planKind))
						localStorage.setItem("teachplan_id", JSON.stringify(plan_id))
						localStorage.setItem("teachsetId", JSON.stringify(response.data.result[0]))
						localStorage.setItem("teachStudy_stu_id", JSON.stringify(plan_id))
						localStorage.setItem("teachrelation_kind", JSON.stringify("errorBook"))
						//为了更改    错题正确率 没有作业相关信息添加下面一行代码
						// localStorage.setItem('teachQuestionSetInformation', '')						
						let beginTimes =(new Date(data.beginTime)).valueOf();
						let endTimes = (new Date(data.endTime)).valueOf()
						let data_1 = {...data,beginTime:beginTimes,endTime:endTimes}
						localStorage.setItem('teachQuestionSetInformation', JSON.stringify(data_1))
						console.log(data_1);
						this.$router.push({
							name: '' + url,
							params: {
								plan_id,
								start_time,
								end_time,
								data
							}
						});
					}
				})
			},
			queryuserplanlist() {
				let data = {
					userId: this.UserListStudentitem.id,
					// planKind: '错题',
					subjectId: JSON.parse(localStorage.getItem("teachsubjectId")),
				}
				queryUserPlanList(data).then(res => {
					res.data.result.list.forEach(item=>{
						const stamp = new Date(item.endTime)
						const time = this.$moment(stamp).format('YYYY-MM-DD HH:mm:ss')
						item.endTime=time
						const stampbeginTime = new Date(item.beginTime)
						const timebeginTime = this.$moment(stampbeginTime).format('YYYY-MM-DD HH:mm:ss')
						item.beginTime=timebeginTime
					})
					this.UserPlanList=res.data.result.list?res.data.result.list.filter(item=>{
						return item.planKind=='错题'|| item.planKind=='个性化'
					}):''
				})
			},
			worksave() {
				if (!this.form.beginTime) {
					this.$message({
						message: '请输入开始时间',
						type: 'warning'
					});
					return
				} else if (!this.form.endTime) {
					this.$message({
						message: '请输入结束时间',
						type: 'warning'
					});
					return
				}
				let planKind
				if(this.cuotiliebiaoshow=='3'){
					planKind='个性化练习'
				}else{
					planKind='错题'
				}
				let data = {
					beginTime: this.form.beginTime,
					endTime: this.form.endTime,
					subjectId: JSON.parse(localStorage.getItem("teachsubjectId")),
					name: this.worksavename,
					planKind: planKind,
					stageKind: "课后",
					resourceRelationList: [{
						relationId: this.setid,
						relationKind: "questionSet",
						relationName: this.UserListStudentitem.name + new Date()
					}],
					userRelationList: [{
						relationId: this.UserListStudentitem.id,
						relationKind: "student",
						relationName: this.UserListStudentitem.name
					}]
				}
				save(data).then(res => {
					this.$message({
						message: '发布成功',
						type: 'success'
					});
					this.worksaveshow = false
					this.cuotiliebiaoshow = true
					this.queryuserplanlist()
				})
			},
			getUserListStudentitem(data) {
				this.UserListStudentitem = data
				this.queryuserplanlist()
			},
			createErrorbookset(kindName) {
				this.kindName=kindName ? "personal" : null
				this.planKind=kindName ?'个性化':'错题'
				// let data = {
				// 	questionOutList: this.tableData,
				// 	userId: this.UserListStudentitem.id,
				// 	userName: this.UserListStudentitem.name,
				// 	subjectId:JSON.parse(localStorage.getItem("teachsubjectId")),
				// 	kindName: kindName ? "personal" : null
				// }
				// createErrorBookSet(data).then(res => {
				// 	this.setid = res.data.result.id
				// 	this.setname = res.data.result.name
				// 	this.worksaveshow = true
				// })
				this.$refs.PublishJob.init()
			},
			generate() {
				let data = {
					questionOutList: this.tableData
				}
				errorBookQuestionPdf(data).then((res) => {
					let content = res.data;
					let elink = document.createElement("a");
					if(this.cuotiliebiaoshow=='3'){
						elink.download = "个性化.pdf";
					}else{
						elink.download = "错题本.pdf";
					}
					elink.style.display = "none";
					let blob = new Blob([content], {
						type: "application/pdf"
					})
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					document.body.removeChild(elink);
				})
			},
			queryallerrorbooklistsona(res){
				if (res) {
					res.forEach((item, index) => {
						item.content = this.textcircled(item.content)
						if (item.answerList && item.answerList.length > 0) {
							item.answerList.forEach((answerListitem, answerListindex) => {
								if (answerListitem.value.indexOf('@@@@') != -1) {
									answerListitem.value = answerListitem.value.replace(
										/@@@@/g,
										'')
									answerListitem.value = answerListitem.value.replace(/\$/g,
										'')
								}
							})
						}
					})
					this.tableData = res
				} else {
					this.tableData = []
				}
				this.Mathqunati()
			},
			queryallerrorbooklist(val) {
				let data = {
					"currPage": 1,
					"pageSize": 100,
					"subjectId": JSON.parse(localStorage.getItem("teachsubjectId")),
					"studentId": this.UserListStudentitem.id,
					"textBookId":JSON.parse(localStorage.getItem('textbookId'))
				}
				if(val){
					queryAllErrorBookList(data).then(res => {
						this.queryallerrorbooklistsona(res.data.result.list)
					})
				}else{
					data.featureList=[{count: 5, featureCode: "100001"},{count: 5, featureCode: "200001"},{count: 5, featureCode: "300001"},{count: 5, featureCode: "400001"},{count: 5, featureCode: "500001"}]
					queryErrorListByStudentCatalogTactics(data).then(res => {
						this.queryallerrorbooklistsona(res.data.result)
					})
				}
			},
			//组题
			queryailistbycatalogtactics() {
				let data = {
					"difficult": this.data.difficult,
					"featureList": this.data.featureList,
					"ids": this.data.ids,
					"relationKind": "keyPoint",
					"subjectId": JSON.parse(localStorage.getItem("teachsubjectId"))
				}
				let data1 = {
					"difficult": "3",
					"featureList": [{
						"count": 2,
						"featureCode": "100001"
					}],
					"ids": [128800],
					"relationKind": "keyPoint",
					"subjectId": 12
				}
				queryAiListByCatalogTactics(data1).then(res => {
					if (res.data.code == '0000') {
						res.data.result.forEach((item, index) => {
							item.content = this.textcircled(item.content)
							item.answerList.forEach((answerListitem, answerListindex) => {
								if (answerListitem.value.indexOf('@@@@') != -1) {
									answerListitem.value = answerListitem.value.replace(/@@@@/g,
										'')
									answerListitem.value = answerListitem.value.replace(/\$/g, '')
								}
							})
						})
						this.tableData = res.data.result
						this.Mathqunati()
					}
				})
			},
			//知识点
			queryrelationlist() {
				let data = {
					"mainIds": JSON.parse(localStorage.getItem("teachchapters")),
					"relationKind": "keyPoint"
				}
				if (data.mainIds) {
					queryRelationList(data).then(res => {
						this.RelationList = res.data.result
						this.data.ids = []
					})
				}
			},
			textcircled(val) {
				if (val) {
					let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫']
					let data = val.replace(/\\textcircled {(1)}/g, a[0])
					data = data.replace(/\\textcircled {(2)}/g, a[1])
					data = data.replace(/\\textcircled {(3)}/g, a[2])
					data = data.replace(/\\textcircled {(4)}/g, a[3])
					data = data.replace(/\\textcircled {(5)}/g, a[4])
					data = data.replace(/\\textcircled {(6)}/g, a[5])
					data = data.replace(/\\textcircled {(7)}/g, a[6])
					data = data.replace(/\\textcircled {(8)}/g, a[7])
					data = data.replace(/\\textcircled {(9)}/g, a[8])
					data = data.replace(/\\textcircled {(10)}/g, a[9])
					data = data.replace(/\\textcelsius/g, "度")
					data = data.replace(/&amp;nbsp;/g, ' ')
					return data
				}
			},
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("title");
				})
			}
		},
	}
</script>

<style>
	.IntelligenceShengspan2 {
		color: black;
		background-color: #FFFFFF;
		border: 1px solid black;
	}

	.Collect_con {
		text-align: left;
		margin: 20px;
		height: 76vh;
	}

	.Collect_top>div>span {
		margin-left: 20px;
		display: inline-block;
	}

	.Collect_top {
		margin: 20px;
	}
	#homeworklist {
		margin-top: 20px;
		height: 75vh;
		overflow: auto;
	}

	.TopicAssembly {
		height: 70vh;
		overflow: auto;
		margin-top: 20px;
	}
</style>
